import Vue from 'vue'
import VueRouter from 'vue-router'
import main_page from '../views/main_page'
import login_page from '../views/login_page.vue'

Vue.use(VueRouter);

export default new VueRouter({
  routes:[
    {
        path: '/',
        name:'main',
        component: main_page
        },
    {
      path:'/login',
      name:'login',
      component:login_page
    }
  ]
});

