<template>  
	<div class="main-page-container">    
		<div class="title">首页</div>    
		<button class="login-btn" @click="login">登录</button>    
	</div>   
  </template>  
	
  <script>  
  export default {  
	name: "MainPage",  
	methods: {
	login() {    
		this.$router.push("/login")  
		}    
	}    
  }  
  </script>  
	
  <style scoped>  
  .main-page-container {  
	display: flex;  
	flex-direction: column;  
	align-items: center;  
	justify-content: center;  
	height: 100vh; /* 使容器占满整个视口高度 */  
	background-color: #f0f2f5; /* 浅灰色背景 */  
  }  
	
  .title {  
	margin-bottom: 20px; /* 与按钮之间的间距 */  
	font-size: 24px; /* 标题字体大小 */  
	font-weight: bold; /* 标题加粗 */  
	color: #333; /* 标题颜色 */  
  }  
	
  .login-btn {  
	padding: 10px 20px; /* 按钮内边距 */  
	font-size: 16px; /* 按钮内文字大小 */  
	color: #fff; /* 按钮文字颜色 */  
	background-color: #007bff; /* 按钮背景颜色 */  
	border: none; /* 去除边框 */  
	border-radius: 5px; /* 边框圆角 */  
	cursor: pointer; /* 鼠标悬停时显示手形图标 */  
	transition: background-color 0.3s; /* 背景颜色过渡效果 */  
  }  
	
  .login-btn:hover {  
	background-color: #0056b3; /* 鼠标悬停时按钮的背景颜色 */  
  }  
  </style>